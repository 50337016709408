.adm-popup {
  --z-index: var(--adm-popup-z-index, 1000);
  position: fixed;
  z-index: var(--z-index);
}

.adm-popup-body {
  position: fixed;
  background-color: #ffffff;
  z-index: calc(var(--z-index) + 10);
}

.adm-popup-body-position-bottom {
  width: 100%;
  bottom: 0;
  left: 0;
}

.adm-popup-body-position-top {
  width: 100%;
  top: 0;
  left: 0;
}

.adm-popup-body-position-left {
  height: 100%;
  top: 0;
  left: 0;
}

.adm-popup-body-position-right {
  height: 100%;
  top: 0;
  right: 0;
}