.adm-input-wrapper {
  --font-size: 0.453333rem;
  --color: var(--adm-color-text);
  --placeholder-color: var(--adm-color-light);
  --disabled-color: var(--adm-color-weak);
  --text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.adm-input {
  flex: auto;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  color: var(--color);
  font-size: var(--font-size);
  line-height: 1.5;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  min-height: 1.5em;
  text-align: var(--text-align);
}

.adm-input::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-input:-ms-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-input::placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-input:-webkit-autofill {
  background-color: transparent;
}

.adm-input:disabled {
  color: var(--disabled-color);
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: var(--disabled-color);
}

.adm-input:read-only {
  cursor: default;
}

.adm-input:invalid {
  box-shadow: none;
}

.adm-input::-ms-clear {
  display: none;
}

.adm-input::-webkit-search-cancel-button {
  display: none;
}

.adm-input::-webkit-search-decoration {
  display: none;
}

.adm-input[type='date'],
.adm-input[type='time'],
.adm-input[type='datetime-local'] {
  min-height: 1.5em;
}

.adm-input[type='search'] {
  -webkit-appearance: none;
}

.adm-input[readonly] {
  pointer-events: none;
}

.adm-input-clear {
  flex: none;
  margin-left: 0.213333rem;
  color: var(--placeholder-color);
  padding: 0.08rem;
  cursor: pointer;
}

.adm-input-clear .antd-mobile-icon {
  display: block;
  font-size: 0.373333rem;
}