.adm-button {
  --color: var(--adm-color-white);
  --text-color: var(--adm-button-text-color, var(--adm-color-text));
  --background-color: var(--adm-button-background-color, var(--adm-color-white));
  --border-radius: var(--adm-button-border-radius, 0.106667rem);
  --border-width: var(--adm-button-border-width, 0.026667rem);
  --border-style: var(--adm-button-border-style, solid);
  --border-color: var(--adm-button-border-color, var(--adm-border-color));
  color: var(--text-color);
  background-color: var(--background-color);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  padding: 0.186667rem 0.426667rem;
  margin: 0;
  font-size: 0.4rem;
  line-height: 1.4;
  text-align: center;
  border: var(--border-width) var(--border-style) var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: opacity ease 0.15s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adm-button:focus {
  outline: none;
}

.adm-button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #000;
  border: inherit;
  border-color: #000;
  border-radius: inherit;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  content: ' ';
  box-sizing: content-box;
}

.adm-button:active::before {
  opacity: 0.1;
}

.adm-button-default.adm-button-fill-outline {
  --background-color: transparent;
  --border-color: var(--adm-color-text);
}

.adm-button-default.adm-button-fill-none {
  --background-color: transparent;
  --border-color: transparent;
}

.adm-button:not(.adm-button-default) {
  --text-color: var(--adm-color-white);
  --background-color: var(--color);
  --border-color: var(--color);
}

.adm-button:not(.adm-button-default).adm-button-fill-outline {
  --text-color: var(--color);
  --background-color: transparent;
}

.adm-button:not(.adm-button-default).adm-button-fill-none {
  --text-color: var(--color);
  --background-color: transparent;
  --border-color: transparent;
}

.adm-button-primary {
  --color: var(--adm-color-primary);
}

.adm-button-success {
  --color: var(--adm-color-success);
}

.adm-button-danger {
  --color: var(--adm-color-danger);
}

.adm-button-warning {
  --color: var(--adm-color-warning);
}

.adm-button-block {
  display: block;
  width: 100%;
}

.adm-button-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.adm-button-disabled:active::before {
  display: none;
}

.adm-button.adm-button-mini {
  padding: 0.08rem 0.186667rem;
  font-size: 0.346667rem;
}

.adm-button.adm-button-mini.adm-button-shape-rounded {
  padding-left: 0.24rem;
  padding-right: 0.24rem;
}

.adm-button.adm-button-small {
  padding: 0.08rem 0.293333rem;
  font-size: 0.4rem;
}

.adm-button.adm-button-large {
  padding: 0.293333rem 0.586667rem;
  font-size: 0.48rem;
}

.adm-button.adm-button-shape-rounded {
  --border-radius: 26.666667rem;
}

.adm-button.adm-button-shape-rectangular {
  --border-radius: 0;
}

.adm-button-loading {
  vertical-align: bottom;
}

.adm-button-loading-wrapper {
  display: flex;
  height: 1.4em;
  align-items: center;
  justify-content: center;
}

.adm-button-loading-wrapper > .adm-loading {
  opacity: 0.6;
}