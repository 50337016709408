.adm-text-area-wrapper {
  --font-size: 0.453333rem;
  --color: var(--adm-color-text);
  --placeholder-color: var(--adm-color-light);
  --disabled-color: var(--adm-color-weak);
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.adm-text-area {
  font-family: var(--adm-font-family);
  resize: none;
  flex: auto;
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  color: var(--color);
  font-size: var(--font-size);
  line-height: 1.5;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  min-height: 1.5em;
}

.adm-text-area::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-text-area:-ms-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-text-area::placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-text-area:-webkit-autofill {
  background-color: transparent;
}

.adm-text-area:disabled {
  color: var(--disabled-color);
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: var(--disabled-color);
}

.adm-text-area:read-only {
  cursor: default;
}

.adm-text-area:invalid {
  box-shadow: none;
}

.adm-text-area::-ms-clear {
  display: none;
}

.adm-text-area[readonly] {
  pointer-events: none;
}

.adm-text-area-count {
  text-align: right;
  color: var(--adm-color-weak);
  font-size: 0.453333rem;
  padding-top: 0.213333rem;
}